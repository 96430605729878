import { mergeQueryParamsIntoObject } from '@dreamstack/util'
import isEqual from 'lodash/isEqual'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { usePropertySearchContext } from '../next/PropertySearchContext'

export const usePropertySearchQueryUrlSync = (active: boolean) => {
  const [appliedUrlParams, setAppliedUrlParams] = useState(false)
  const {
    propertySearchFilterValues,
    setPropertySearchFilterValues,
    filterTouched,
    forcePropertySearchQueryUrlSyncUrl,
    betterDefaultFilterValues,
  } = usePropertySearchContext()

  const router = useRouter()
  const query = router.query

  // UI --> Query
  useEffect(() => {
    // Set Timeout to catch filterTouched flipping from false to true to false,
    // when navigation from city to city overview
    const timeoutRef = setTimeout(() => {
      const mergedFilter = mergeQueryParamsIntoObject(
        betterDefaultFilterValues,
        query
      )
      if (!active) return
      const filterStateAtDefaultButUrlQueryStateNot =
        !isEqual(mergedFilter, betterDefaultFilterValues) &&
        isEqual(propertySearchFilterValues, betterDefaultFilterValues)
      if (filterTouched || filterStateAtDefaultButUrlQueryStateNot) {
        const pathname =
          forcePropertySearchQueryUrlSyncUrl || `${router.asPath.split('?')[0]}`
        router.replace(
          {
            pathname,
            query: { ...propertySearchFilterValues },
          },
          undefined,
          { shallow: true }
        )
      }
    }, 0)
    return () => clearTimeout(timeoutRef)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    propertySearchFilterValues,
    active,
    filterTouched,
    forcePropertySearchQueryUrlSyncUrl,
    // router, ROUTER IS NOT MEMOIZED!! Triggers all the rerenders...
  ])

  // Query --> UI
  useEffect(() => {
    if (!active) return
    if (appliedUrlParams) {
      return
    }

    const mergedFilter = mergeQueryParamsIntoObject(
      betterDefaultFilterValues,
      query
    )

    const shouldApplyFilter = !isEqual(mergedFilter, betterDefaultFilterValues)

    if (shouldApplyFilter) {
      setAppliedUrlParams(true)
      setPropertySearchFilterValues(mergedFilter)
    } else {
      // console.log('nothing to do here')
    }
  }, [
    query,
    active,
    betterDefaultFilterValues,
    appliedUrlParams,
    setPropertySearchFilterValues,
  ])
}
