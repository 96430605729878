import { useCallback } from 'react'
import { useLocale } from '../locale/useLocale'

/**
 *
 * @param options Remember to pass only memoized objects!
 * @returns useCallback of intl.numberformat fn
 */

export const useFormatNumber = (options: Intl.NumberFormatOptions) => {
  const locale = useLocale()
  return useCallback(
    (value: number | string) => {
      let val: number
      if (typeof value === 'number') val = value
      else val = parseFloat(value)

      return new Intl.NumberFormat(locale, options).format(val)
    },
    [locale, options]
  )
}
