import cloneDeep from 'lodash/cloneDeep'

// export type TypesOfImmo = 'house' | 'apartment' | 'industry'
/* NOTE: Values here must be kept in sync with content-model "Region Description Text" in contentful */
export const allRentedTypes = ['rented', 'not-rented', 'both'] as const
export type RentedTypes = typeof allRentedTypes[number]
/* NOTE: Values here must be kept in sync with content-model "Region Description Text" in contentful */
export type ConstructionAgeType = 'oldbuilding' | 'newbuilding' | 'both'
/* NOTE: Values here must be kept in sync with content-model "Region Description Text" in contentful */
export type RsCategory =
  | 'raisedGroundFloor'
  | 'groundFloor'
  | 'roofStorey'
  | 'maisonette'
  | 'apartment'

export const RsCategoryFilterValueMapping: { [key: string]: RsCategory } = {
  RAISED_GROUND_FLOOR: 'raisedGroundFloor',
  GROUND_FLOOR: 'groundFloor',
  MAISONETTE: 'maisonette',
  ROOF_STOREY: 'roofStorey',
  APARTMENT: 'apartment',
}

/* NOTE: Values here must be kept in sync with content-model "Region Description Text" in contentful */
export type OtherFilterOptions = 'waterClose' | 'lift' | 'balconyTerrace'

export enum SortByOptions {
  FIRST_PUBLISHED_AT_DESC = 'first_published_at_desc',
  FIRST_PUBLISHED_AT_ASC = 'first_published_at_asc',
  PRICE_ASC = 'price_asc',
  PRICE_DESC = 'price_desc',
}

export interface PropertySearchFilterValues {
  regions: string[]
  cities: string[]
  locations: string[]
  priceRange: [number, number]
  roomRange: [number, number]
  livingspace: [number, number]
  sortBy: SortByOptions
  sortDirection: 'asc' | 'desc'
  value: 'first_published_at' | 'price'
  typeOfImmo: string
  rented: RentedTypes
  textSearch: string
  constructionAgeType: ConstructionAgeType
  rsCategories: RsCategory[]
  otherFilters: OtherFilterOptions[]
}

export const MaxFilterValue = {
  price: 999000,
  roomRange: 10,
  livingSpace: 1000,
}

export const defaultFilterValues: PropertySearchFilterValues = {
  cities: [],
  locations: [],
  regions: [],
  priceRange: [0, MaxFilterValue.price],
  roomRange: [0, MaxFilterValue.roomRange],
  livingspace: [0, MaxFilterValue.livingSpace],
  sortBy: SortByOptions.FIRST_PUBLISHED_AT_DESC,
  value: 'first_published_at',
  sortDirection: 'desc',
  typeOfImmo: 'all',
  rented: 'both',
  textSearch: '',
  constructionAgeType: 'both',
  rsCategories: [],
  otherFilters: [],
}

export const getDefaultFilterValues = () => cloneDeep(defaultFilterValues)
