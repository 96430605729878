export enum PropertyStatus {
  FREE = 1,
  BLOCKED = 2,
  NOT_AVAILABLE = 3,
  RESERVED = 4,
  SOLD = 5,
  FREE_STOP_AD = 6,
  PRERESERVED = 40,
  RESERVATION_EXTENSION_1 = 67,
  RESERVATION_EXTENSION_2 = 68,
  RESERVATION_EXTENSION_3 = 69,
  DECLARATION_OF_PURCHASE_INTENTION = 70,
  FREE_EXCLUSIVE_COOP = 201,
  FREE_EXCLUSIVE_SALES_CONSULTANT = 199,
}

export const PROPERTY_STATUS_FREE = [
  PropertyStatus.FREE,
  PropertyStatus.FREE_STOP_AD,
  PropertyStatus.FREE_EXCLUSIVE_COOP,
  PropertyStatus.FREE_EXCLUSIVE_SALES_CONSULTANT,
]

export const PropertyStatusOpenReservation = [
  PropertyStatus.PRERESERVED,
  PropertyStatus.RESERVED,
  PropertyStatus.RESERVATION_EXTENSION_1,
  PropertyStatus.RESERVATION_EXTENSION_2,
  PropertyStatus.RESERVATION_EXTENSION_3,
  PropertyStatus.DECLARATION_OF_PURCHASE_INTENTION,
]

// TODO: define from https://docs.google.com/spreadsheets/d/11e_FCccRtK4v9KHlHPHbvDio14-G7fMaLG8oHgXZgMo/edit#gid=0
export const PROPERTY_STATUS_FOLLOW_INDEX = [
  PropertyStatus.FREE,
  PropertyStatus.FREE_STOP_AD,
  PropertyStatus.FREE_EXCLUSIVE_COOP,
  PropertyStatus.RESERVED,
  PropertyStatus.PRERESERVED,
  PropertyStatus.RESERVATION_EXTENSION_1,
  PropertyStatus.RESERVATION_EXTENSION_2,
  PropertyStatus.RESERVATION_EXTENSION_3,
  PropertyStatus.FREE_EXCLUSIVE_SALES_CONSULTANT,
]

export const PROPERTY_STATUS_PRICE_DETAILS_IN_API = [
  PropertyStatus.FREE,
  PropertyStatus.FREE_STOP_AD,
  PropertyStatus.FREE_EXCLUSIVE_COOP,
  PropertyStatus.PRERESERVED,
  PropertyStatus.RESERVED,
  PropertyStatus.RESERVATION_EXTENSION_1,
  PropertyStatus.RESERVATION_EXTENSION_2,
  PropertyStatus.RESERVATION_EXTENSION_3,
  PropertyStatus.DECLARATION_OF_PURCHASE_INTENTION,
  PropertyStatus.FREE_EXCLUSIVE_SALES_CONSULTANT,
]
