import isEqual from 'lodash/isEqual'
import type { FunctionComponent } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { usePropertySearchQueryUrlSync } from '../hooks/usePropertySearchQueryUrlSync'
import type {
  ConstructionAgeType,
  OtherFilterOptions,
  PropertySearchFilterValues,
  RentedTypes,
  RsCategory,
} from './PropertySearchFilter'
import { MaxFilterValue, defaultFilterValues } from './PropertySearchFilter'

const reservationUiFilterValues = {
  showFree: true,
  showMyReservations: true,
  showHidden: false,
}
export type ReservationUiFilterValues = typeof reservationUiFilterValues

const transformStaticFilterValues = (
  staticFilter: StaticFilter
): Partial<PropertySearchFilterValues> => {
  // Edge case for Berlin, because Berlin is viewed as City and Location, but should be viewed as City only
  if (staticFilter.city && staticFilter.location) {
    if (staticFilter.city === staticFilter.location) {
      staticFilter.location = undefined
    }
  }
  return {
    cities: staticFilter.city ? [staticFilter.city] : [],
    locations: staticFilter.location ? [staticFilter.location] : [],
    rsCategories: (staticFilter.rsCategory
      ? staticFilter.rsCategory
      : []) as RsCategory[],
    otherFilters: (staticFilter.otherFilter
      ? staticFilter.otherFilter
      : []) as OtherFilterOptions[],
    rented: (staticFilter.rented as RentedTypes) ?? 'both',
    constructionAgeType:
      (staticFilter.constructionAge as ConstructionAgeType) ?? 'both',
    livingspace: [
      staticFilter.squareMetersMin ?? 0,
      staticFilter.squareMetersMax ?? MaxFilterValue.livingSpace,
    ],
    roomRange: [
      staticFilter.roomsMin ?? 0,
      staticFilter.roomsMax ?? MaxFilterValue.roomRange,
    ],
  }
}

const useAppContextState = (options: PropertySearchContextProviderOptions) => {
  const { staticFilter } = options
  const betterDefaultFilterValues = useMemo(() => {
    if (staticFilter) {
      return {
        ...defaultFilterValues,
        ...transformStaticFilterValues(staticFilter),
      }
    }

    return defaultFilterValues
  }, [staticFilter])

  const [propertySearchFilterValues, setPropertySearchFilterValuesState] =
    useState(betterDefaultFilterValues)

  const [
    propertySearchReservationUiFilter,
    setPropertySearchReservationUiFilter,
  ] = useState(reservationUiFilterValues)

  const setPropertySearchFilterValues = useCallback(
    (newValues: Partial<PropertySearchFilterValues>) => {
      setPropertySearchFilterValuesState((currentValues) => ({
        ...currentValues,
        ...newValues,
      }))
    },
    [setPropertySearchFilterValuesState]
  )

  useEffect(() => {
    if (staticFilter) {
      setPropertySearchFilterValues({
        ...transformStaticFilterValues(staticFilter),
      })
    }
  }, [staticFilter, setPropertySearchFilterValues])

  const filterTouched = useMemo(() => {
    return !isEqual(propertySearchFilterValues, betterDefaultFilterValues)
  }, [propertySearchFilterValues, betterDefaultFilterValues])

  const noPlaceSelected =
    !propertySearchFilterValues.cities?.length &&
    !propertySearchFilterValues.regions?.length &&
    !propertySearchFilterValues.locations?.length

  return {
    noPlaceSelected,
    propertySearchFilterValues,
    filterTouched,
    setPropertySearchFilterValues,
    betterDefaultFilterValues,
    propertySearchReservationUiFilter,
    setPropertySearchReservationUiFilter,
    ...options,
  }
}

type IPropertySearchContext = ReturnType<typeof useAppContextState>

export const PropertySearchContext = createContext({} as IPropertySearchContext)

export type StaticFilter = {
  location?: string | null
  city?: string | null
  rsCategory?: (string | null)[] | null
  otherFilter?: (string | null)[] | null
  rented?: string | null
  constructionAge?: string | null
  roomsMin?: number | null
  roomsMax?: number | null
  squareMetersMin?: number | null
  squareMetersMax?: number | null
}

export type PropertySearchContextProviderOptions = {
  isReservationUi?: boolean
  reservationProjectIds?: number[]
  staticFilter?: StaticFilter
  activatePropertySearchQueryUrlSync?: boolean
  forcePropertySearchQueryUrlSyncUrl?: string
  seoText?: { json: any }
}

export const PropertySearchContextProvider: FunctionComponent<
  React.PropsWithChildren<PropertySearchContextProviderOptions>
> = ({ children, ...options }) => {
  const context: IPropertySearchContext = useAppContextState(options)
  return (
    <PropertySearchContext.Provider value={context}>
      <PropertySearchContextSideEffects />
      {children}
    </PropertySearchContext.Provider>
  )
}

const PropertySearchContextSideEffects: FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const { activatePropertySearchQueryUrlSync } = usePropertySearchContext()
  usePropertySearchQueryUrlSync(!!activatePropertySearchQueryUrlSync)
  return null
}

export function usePropertySearchContext() {
  return useContext(PropertySearchContext)
}
