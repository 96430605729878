import { useFormatToCurrencyCutoff, useFormatToDecimal } from '@dreamstack/i18n'
import { PropertyStatus } from '@dreamstack/web-graphql'
import type { PropertyPreview } from '@dreamstack/web/lib/prisma/properties'
import { useCallback } from 'react'

export const usePropertyPriceVisibility = () => {
  return useCallback(propertyPriceVisibility, [])
}

export const propertyPriceVisibility = ({
  status,
  price,
}: {
  status?: PropertyStatus | null
  price?: number | null
}) => {
  const hasPrice = !(price == null)
  if (!hasPrice) return false
  switch (status) {
    case PropertyStatus.BLOCKED:
    case PropertyStatus.NOT_AVAILABLE:
    case PropertyStatus.RESERVED:
    case PropertyStatus.RESERVATION_EXTENSION_1:
    case PropertyStatus.RESERVATION_EXTENSION_2:
    case PropertyStatus.RESERVATION_EXTENSION_3:
    case PropertyStatus.DECLARATION_OF_PURCHASE_INTENTION:
      return false
    default:
      return true
  }
}

export const useTransformPropertyPrice = () => {
  const propertyPriceVisibility = usePropertyPriceVisibility()

  const formatCurrencyWithDecimals = useFormatToDecimal()
  const formatCurrency = useFormatToCurrencyCutoff()

  return useCallback(
    (property: Pick<PropertyPreview, 'status' | 'price' | 'price_per_sqm'>) => {
      const hasPrice = propertyPriceVisibility({
        status: property.status ?? undefined,
        price: property.price ?? undefined,
      })
      const price =
        hasPrice && property.price ? formatCurrency(property.price) : '???.???'
      const pricePerSqm =
        hasPrice && property.price_per_sqm
          ? formatCurrencyWithDecimals(property.price_per_sqm)
          : '???'

      return {
        hasPrice,
        price,
        pricePerSqm,
      }
    },
    [formatCurrency, formatCurrencyWithDecimals, propertyPriceVisibility]
  )
}
