import forEach from 'lodash/forEach'
import get from 'lodash/get'
import map from 'lodash/map'
import type { ParsedUrlQuery } from 'querystring'
import { getAsArray } from './array-util'

export const getParamAsArray = (query: ParsedUrlQuery, key: string) => {
  getAsArray(get(query, key, []))
}

export const mergeQueryParamsIntoObject = <T extends any>(
  baseObject: T,
  query: ParsedUrlQuery
) => {
  const baseCopy = { ...(baseObject as any) }

  const relevantKeysInQuery = Object.keys(query).filter(
    (key) => key in baseCopy
  )

  forEach(relevantKeysInQuery, (key) => {
    const baseType = typeof baseCopy[key]
    const queryType = typeof query[key]
    if (baseType !== queryType) {
      if (baseType === 'number') {
        baseCopy[key] = +(query[key] as any)
      } else if (baseType === 'object') {
        baseCopy[key] = [query[key]]
      }
    } else if (
      baseType === 'object' &&
      queryType === 'object' &&
      baseCopy[key].length &&
      typeof baseCopy[key][0] === 'number'
    ) {
      // console.log('query[key]:', query[key])

      baseCopy[key] = map(query[key], (entry) => +entry)
    } else {
      baseCopy[key] = query[key]
    }
  })

  return baseCopy as T
}
